.action-button-group-container {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  .create-new-project-btn {
    display: flex;
    padding: 9px 45.804px 9px 59px;
    align-items: center;
    gap: 6.571px;
    height: 41px;

    border-radius: 8px;
    background: #f68b0a;
    border: 1px solid #f68b0a;

    width: 100%;

    &:hover {
      background: #f68b0a;
      border: 1px solid #f68b0a;
    }
  }

  .action-btn-group-wrapper {
    display: none;
    gap: 31px;

    button {
      gap: 18px;
      border-radius: 8px;
      padding: 0.3rem 0.1rem;

      img {
        width: 1vw;
        height: 1.8vh;
      }

      label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
        cursor: pointer;
      }
    }
  }

  .selected-other-group {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;

    .selected-btn {
      gap: 16px;
      padding: 0.3rem 0.1rem;
      border-radius: 8px;

      img {
        width: 16px;
      }

      label {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;

        cursor: pointer;
      }
    }

    .more-btn {
      display: block;

      img {
        width: 3vw;
        height: 3vh;
        cursor: pointer;
      }
    }
  }

  .p-button.p-button-text:enabled:hover,
  .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(33, 149, 243, 0.21);
    color: #2196f3;
    border-color: transparent;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    // black
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    // brown
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    // red
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    // orange
    flex-direction: row;
    .create-new-project-btn {
      width: unset;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    // yellow
    .create-new-project-btn {
      width: unset;
    }
  }

  /* more Extra large devices (large laptops and desktops, 1600px and up) */
  @media only screen and (min-width: 1800px) {
    // green
    justify-content: space-between;
    gap: 41px;
    .create-new-project-btn {
      width: unset;
    }
    .action-btn-group-wrapper {
      display: flex;
    }

    .selected-other-group {
      .more-btn {
        display: none;
      }
    }
  }
}

.btn-create-project-footer {
  .cancle-btn {
  }
  .create-btn {
    background: #f68b0a;
    border: 1px solid #f68b0a;

    &:hover {
      background: #f68b0a;
      border: 1px solid #f68b0a;
    }
  }
}
