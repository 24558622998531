.top-bar-container {
  .logo-wrapper {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    padding: 40px;
    z-index: 100;

    .header-text {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 126%;
      letter-spacing: -0.5px;
    }
  }

  .user-info-wrapper {
    position: absolute;
    top: 0;
    right: 0;

    z-index: 100;

    padding: 40px 40px;

    img {
      width: 38px;
      height: 38px;
      user-select: none;
    }

    .inner-user-info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 8px 0;
    }
  }
}
