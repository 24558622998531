.seach-bar-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .seach-bar {
    width: 100%;

    .p-inputtext {
      width: 100%;
      display: flex;
      gap: 8px;
      flex-shrink: 0;

      border-radius: 8px;
      border: 1px solid #969696;
      background: var(--line-bg-box, #f4f4f4);
    }
  }

  .btn-groub-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .seach-bar-btn {
      display: flex;
      width: 50%;
      height: 41px;
      padding: 0px 12px;
      gap: 10px;

      border-radius: 8px;
      background: #f68b0a;
      border: 1px solid #f68b0a;

      &:hover {
        background: #f68b0a;
        border: 1px solid #f68b0a;
      }
    }

    .icon-groun-wrapper {
      width: 50%;
      display: flex;
      align-items: center;
      padding: 0 16px;
      gap: 25px;

      img {
        width: 3vw;
        height: 3vh;
        user-select: none;
      }
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    // black
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    // brown
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    // red
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    // orange
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    // yellow
    flex-direction: row;

    .seach-bar {
      width: 66%;
    }

    .btn-groub-wrapper {
      width: 34%;

      .seach-bar-btn {
        width: 50%;
      }

      .icon-groun-wrapper {
        width: 50%;
      }
    }
  }

  /* more Extra large devices (large laptops and desktops, 1600px and up) */
  @media only screen and (min-width: 1600px) {
    // green
  }
}
