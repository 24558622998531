.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #989898 0%, #525150 100%);
  position: relative;

  .content-wrapper {
    display: flex;
    width: 66.67vw;
    height: 91.2vh;
    padding: 34px;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    flex-shrink: 0;

    border-radius: 20px;
    background: rgba(244, 244, 244, 0.5);

    position: relative;

    .back-arrow {
      position: absolute;
      top: 15px;
      left: 15px;

      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff00;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff00;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    // black
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    // brown
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    // red
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    // orange
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    // yellow
  }

  /* more Extra large devices (large laptops and desktops, 1600px and up) */
  @media only screen and (min-width: 1200px) {
    // green
  }
}
