.dialog-wrapper {
  .share-modal {
    .header-user-sharing {
      font-size: 20px;
    }

    .card-user-sharing {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      padding: 0.5rem 0rem;

      .sharing-card-header {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .sharing-remove-btn {
        cursor: pointer;
        &:hover {
          background-color: #dee2e6 !important;
        }
      }
    }
  }

  .finish-btn {
    border-radius: 8px;
    background: #f68b0a;
    border: 1px solid #f68b0a;

    &:hover {
      background: #f68b0a !important;
      border: 1px solid #f68b0a !important;
    }
  }

  .chips-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .p-chips-token {
    margin: 0.2rem;
  }

  .sharing-add-user-btn {
    margin-top: 0.5rem;
    width: 100%;
  }
}
