.error-red-color {
    color: #EF4444 !important;
}

.error-red-bg {
    background: #EF4444 !important;
}

.line-bg-box-color {
    color: #F4F4F4 !important;
}

.line-bg-box-bg {
    background: #F4F4F4 !important;
}

.line-gray-color {
    color: #818181 !important;
}

.line-gray-bg {
    background: #818181 !important;
}

.main-darker-01-color {
    color: #19130D !important;
}

.main-darker-01-bg {
    background: #19130D !important;
}

.main-darker-02-color {
    color: #3A3028 !important;
}

.main-darker-02-bg {
    background: #3A3028 !important;
}

.main-orange-01-color {
    color: #F68B0A !important;
}

.main-orange-01-bg {
    background: #F68B0A !important;
}

.neutral-color {
    color: #191D23 !important;
}

.neutral-bg {
    background: #191D23 !important;
}

.white-color {
    color: #FFFFFF !important;
}

.white-bg {
    background: #FFFFFF !important;
}

.black-color {
    color: #000000 !important;
}

.black-bg {
    background: #000000 !important;
}

.dark-brown-color {
    color: #19130D !important;
}

.dark-brown-bg {
    background: #19130D !important;
}


.light-brown-color {
    color: #A67C52 !important;
}

.light-brown-bg {
    background: #A67C52 !important;
}

.grey-color {
    color: #969696 !important;
}

.grey-bg {
    background: #969696 !important;
}

.red-color {
    color: #DC2626 !important;
}

.red-bg {
    background: #DC2626 !important;
}

.orange-color {
    color: #DC7800 !important;
}

.orange-bg {
    background: #DC7800 !important;
}