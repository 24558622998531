.card-folder {
  cursor: pointer;

  display: inline-flex;
  padding: 18px 16px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #cdcfd0;
  background: #fff;

  height: 68px;

  gap: 6px;

  &.card-dragged {
    position: absolute;
    transform-origin: bottom left;
    -webkit-backface-visibility: hidden;

    &.list {
      width: 1200px;
    }
  }

  &.selected {
    border: 2px solid #f68b0a;
  }

  &.list {
    width: 100%;

    .card-name {
      width: 100%;
    }
  }

  &.folder {
    width: 285px;
  }

  .img-group {
    position: relative;

    .folder-img {
      width: 32px;
      filter: invert(54%) sepia(74%) saturate(1219%) hue-rotate(358deg)
        brightness(99%) contrast(95%);
    }
    .share-img {
      width: 18px;
      position: absolute;
      bottom: 0;
      left: -6px;
    }
  }

  .card-name {
    display: flex;
    width: 167px;
    height: 22px;
    overflow: hidden;
    white-space: nowrap;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 136%;
    letter-spacing: 0.1px;
  }

  .pi-star-fill {
    color: #2bace3;
  }
}

.card.combined {
  background-color: #f68b0a;
  // border: 1px dashed #666;
}
