.folder-container {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  width: 100%;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  padding: 10px;
}
