.header-wrapper {
  width: 100%;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px 26px;
  .p-breadcrumb {
    background: transparent;
    border: 1px solid transparent;
    padding: 0.1rem;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
    letter-spacing: 0.25px;
    height: 27px;
  }

  .header-text {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    letter-spacing: -0.5px;
    margin-top: 14px;
  }

  .sharing-btn {
    position: absolute;
    top: 0;
    right: 0;

    cursor: pointer;

    &:hover {
      background-color: #dee2e6 !important;
    }
  }
}
