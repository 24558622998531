.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #f1efed 0%, #cdcbc9 100%);

  .login-wrapper {
    width: 420px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.6);
    padding: 75px 60px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .header-text {
        font-size: 40px;
      }
    }

    .input-wrapper {
      .header-input-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
        height: 27px;
      }
      .p-inputtext {
        width: 100%;
        border-radius: 4px;
      }

      .p-error {
        color: #dc2626;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 144%;
        letter-spacing: 0.17px;
        height: 19px;
      }

      .password-header-warpper {
        height: 27px;
        display: flex;
        justify-content: space-between;

        .forgot-password {
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 166%;
          letter-spacing: 0.4px;

          cursor: pointer;
        }
      }

      .checkbox {
        margin-top: 20px;
        margin-bottom: 24px;

        .p-checkbox .p-checkbox-box.p-highlight {
          border-color: #f68b0a;
          background: #f68b0a;
        }
      }

      .login-btn {
        display: flex;
        height: 44px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;

        background: #f68b0a;
        border: 1px solid #f68b0a;

        border-radius: 4px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
      }

      .social-login-btn {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        button {
          width: 100%;
          justify-content: center;
          gap: 8px;

          border-radius: 4px;
          border: 1px solid #818181;

          label {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
            letter-spacing: 0.1px;
            cursor: pointer;
          }

          img {
            width: 28px;
          }
        }
      }
    }

    .sign-up-wrapper {
      .divider {
        padding: 0 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 35px;
        .p-divider .p-divider-content {
          background-color: rgba(255, 255, 255, 0.6);
        }

        .p-divider {
          width: 40px;
        }
        .divider-text {
          height: 19px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 136%;
          letter-spacing: 0.15px;

          width: max-content;
        }
      }

      .sign-up-btn-group-wrapper {
        display: flex;
        flex-wrap: nowrap;
        gap: 4px;

        margin-top: 5px;
        button {
          width: 50%;
          height: 38px;
          justify-content: center;
          gap: 6px;
          label {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
            letter-spacing: 0.1px;
            cursor: pointer;
          }

          img {
            width: 16px;
          }
        }
      }
    }

    .sigup-text-wrapper {
      text-align: center;
      height: 19px;
      color: var(--neutral-800, #191d23);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 136%;
      letter-spacing: 0.15px;

      .sing-up-link {
        font-weight: 700;
        line-height: 144%;
        text-decoration-line: underline;

        cursor: pointer;
      }
    }
  }
}
