.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #f1efed 0%, #cdcbc9 100%);

  .register-wrapper {
    width: 420px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.6);
    padding: 75px 60px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .header-text {
        font-size: 40px;
      }
    }

    .input-wrapper {
      .header-input-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
        height: 27px;
      }
      .p-inputtext {
        width: 100%;
        border-radius: 4px;
      }

      .p-error {
        color: #dc2626;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 144%;
        letter-spacing: 0.17px;
        height: 19px;
      }

      .password-header-warpper {
        height: 27px;
        display: flex;
        justify-content: space-between;

        .forgot-password {
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 166%;
          letter-spacing: 0.4px;

          cursor: pointer;
        }
      }

      .register-btn {
        display: flex;
        height: 44px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;

        background: #f68b0a;
        border: 1px solid #f68b0a;

        border-radius: 4px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
      }

      .back-btn {
        margin-top: 10px;
        display: flex;
        height: 44px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        width: 100%;
        border-radius: 4px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: 0.1px;
      }
    }
  }
}
